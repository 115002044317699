




































import { Component, Vue, Watch, Prop, PropSync } from "vue-property-decorator";
import modelo_horarioModule from "@/store/modules/modelo_horario-module";
import { modelo_horario } from "@/shared/dtos/horarios/modelo_horario";
@Component({
  components: { CompHeader: () => import("./CompHeaderLabel.vue") }
})
export default class CompTipoDeHorario extends Vue {
  @PropSync("horario", { type: String }) syncedhorario!: string;
  @Prop() index!: number;
  public horarioSeleccionado: any = {
    text: "",
    value: -1
  };
  public async created() {
    await modelo_horarioModule.getmodelo_horarios();
  }
  public get horarios() {
    let valores: any[] = [];
    for (let i = 0; i < modelo_horarioModule.modelo_horarios.length; i++) {
      const element = modelo_horarioModule.modelo_horarios[i];
      valores.push({
        text: element.nombre,
        value: element.id
      });
      if (this.syncedhorario === element.nombre) {
        this.horarioSeleccionado = valores[valores.length - 1];
        this.$emit("validado", { index: this.index, valido: true });
      }
    }
    return valores;
  }
  @Watch("horarioSeleccionado")
  public ChangeHorario() {
    for (let i = 0; i < modelo_horarioModule.modelo_horarios.length; i++) {
      const element = modelo_horarioModule.modelo_horarios[i];
      if (element.id === this.horarioSeleccionado) {
        this.$emit("validado", { index: this.index, valido: true });
        this.syncedhorario = element.nombre;
      }
    }
  }
  public get ItemSelect(): modelo_horario {
    for (let i = 0; i < modelo_horarioModule.modelo_horarios.length; i++) {
      const element = modelo_horarioModule.modelo_horarios[i];
      if (this.syncedhorario === element.nombre) {
        this.$emit("validado", { index: this.index, valido: true });
        return element;
      }
    }
    return new modelo_horario();
  }
}
